import React from 'react';
import GroupFamily from '../Image/GroupTravel/GroupFamily';
import GroupSchool from '../Image/GroupTravel/GroupSchool';
import GroupReligious from '../Image/GroupTravel/GroupReligious';
import GroupCorporate from '../Image/GroupTravel/GroupCorporate';
import GroupSport from '../Image/GroupTravel/GroupSport';
import GroupWedding from '../Image/GroupTravel/GroupWedding';

import './GroupTravelProgram.css';

const GroupTravelProgram = () => (
  <div className="group-program-block">
    <div className="group-program-text text-block">
      <h2>Who do we book Group Travel for?</h2>
      <p>
        If you're flying with 5 or more people, our Group Travel Program is for
        you!
      </p>
    </div>
    <div className="group-program-items">
      <div className="item">
        <GroupFamily alt="GroupFamily" />
        <p>Family & Friends Group</p>
      </div>
      <div className="item">
        <GroupSchool alt="GroupSchool" />
        <p>School Groups</p>
      </div>
      <div className="item">
        <GroupReligious alt="GroupReligious" />
        <p>Religious Groups</p>
      </div>
      <div className="item">
        <GroupCorporate alt="GroupCorporate" />
        <p>Corporate Groups</p>
      </div>
      <div className="item">
        <GroupSport alt="GroupSport" />
        <p>Sport Groups</p>
      </div>
      <div className="item">
        <GroupWedding alt="GroupWedding" />
        <p>Wedding Groups</p>
      </div>
    </div>
  </div>
);

export default GroupTravelProgram;

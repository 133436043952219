import React from 'react';
import PropTypes from 'prop-types';
import { baseIBEURL } from '../../configs';
import SearchForm from '../FindFlightsBlock/SearchForm';
import { normalizeInput } from '../../helpers/utils';
import { formatPhone } from '../../helpers/callTracking';
import { getUrlParams } from '../../helpers/urlParams';
import { GetGroupPageSearchFormSave } from '../../services/apiService';
import { formatDateApi } from '../../helpers/dateHelper';

import './GroupPageSearchForm.css';

class GroupPageSearchForm extends React.Component {
  static propTypes = {
    phoneNumber: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    const {
      serviceClass,
      adults,
      children,
      infants,
      startDate,
      endDate,
      from,
      to
    } = getUrlParams({
      filter: {
        data: [
          'serviceClass',
          'adults',
          'children',
          'infants',
          'startDate',
          'endDate',
          'from',
          'to'
        ],
        type: 'all'
      }
    });
    this.state = {
      name: {
        value: '',
        hasError: true,
        errorText: 'Please provide a name',
        validation: val => val !== '',
        onChange: val => val
      },
      email: {
        value: '',
        hasError: true,
        errorText: 'Please provide a correct email address',
        validation: val => this.emailValidation(val),
        onChange: val => val
      },
      contactNumber: {
        value: '',
        hasError: true,
        errorText: 'Please provide a contact number',
        validation: val => val.length === 16,
        onChange: (newVal, oldVal) => {
          return normalizeInput(newVal, oldVal);
        }
      },
      note: {
        value: '',
        hasError: true,
        errorText: 'Please provide a note',
        validation: val => val !== '',
        onChange: val => val
      },
      bestTimeToCall: {
        value: '8am-1pm',
        hasError: false,
        validation: val => val,
        onChange: val => val
      },
      serviceClass,
      adults,
      children,
      infants,
      startDate,
      endDate,
      from,
      to,
      isShowError: false,
      displaySuccessMessage: false
    };
  }

  emailValidation = val => {
    return /^[^@]+@[^@]+\.[^@]+$/.test(val);
  };

  handleChange = e => {
    let name = e.target.name;
    let value = e.target.value;
    const temp = this.state[name];

    this.setState({
      [name]: {
        ...temp,
        value: temp.onChange(value, temp.value),
        hasError: !temp.validation(value)
      }
    });
  };

  handleChangeSelect = e => {
    this.setState({
      bestTimeToCall: {
        value: e.target.value,
        hasError: false,
        validation: val => val !== ''
      }
    });
  };

  checkError = () => {
    if (this.state.contactNumber.hasError) {
      this.setState({
        isShowError: true
      });
      return true;
    } else {
      return false;
    }
  };

  onSubmit = data => {
    const { name, email, note, contactNumber, bestTimeToCall } = this.state;
    const serviceClass = data.cabinClass
      .trim()
      .toUpperCase()
      .replace(/\s/g, '-');
    const timeToCall = bestTimeToCall.value.toLowerCase();
    const result = {
      origin: data.origin,
      destination: data.destination,
      departureDate: formatDateApi(data.startDate),
      arrivalDate: formatDateApi(data.endDate),
      serviceClass,
      adults: data.passengers.adults,
      children: data.passengers.children,
      infants: data.passengers.infants,
      ...(name.value && { name: name.value }),
      ...(email.value && { email: email.value }),
      phone: contactNumber.value,
      bestTimeToCall: timeToCall,
      ...(note.value && { message: note.value })
    };

    if (!this.checkError()) {
      this.setState({ displaySuccessMessage: true });
      GetGroupPageSearchFormSave({ ...result });
    }
  };

  render() {
    const { phoneNumber, groupTravelView } = this.props;
    const {
      isShowError,
      name,
      email,
      note,
      contactNumber,
      bestTimeToCall,
      serviceClass,
      adults,
      children,
      infants,
      startDate,
      endDate,
      from,
      to,
      displaySuccessMessage
    } = this.state;
    return (
      <div className="search-form-block">
        <div className="search-form-wrapper ">
          <div className="text-block">
            <h2>How to contact us for Group Travel</h2>
            <p>
              Send us an email to GroupTravel@travelopod.com to get your no
              obligation consultation with one of our group travel experts.
              <br />
              You can also call us at{' '}
              <span className="phone-text">{formatPhone(phoneNumber)}</span> OR
              fill up the group inquiry form.
            </p>
          </div>
          {!displaySuccessMessage && (
            <SearchForm
              baseIBEURL={baseIBEURL}
              big={false}
              submitButtonText="Enter now"
              destination={to}
              origin={from}
              serviceClass={serviceClass}
              startDate={startDate}
              endDate={endDate}
              passengers={{
                ...(adults && { adults: parseInt(adults, 10) - 1 }),
                ...(children && { children: parseInt(children, 10) }),
                ...(infants && { infants: parseInt(infants, 10) })
              }}
              updatePhone={() => {}}
              getInfoSearch={() => {}}
              showOnlyTab="RoundTripTab"
              maxPassengersCount={999}
              minPassengersCount={5}
              big={true}
              groupTravelView={groupTravelView}
              setError={this.checkError}
              onSubmit={this.onSubmit}
            >
              <div className="search-input">
                <input
                  id="name"
                  className="submit-input input-bg-white"
                  name="name"
                  value={name.value || ''}
                  onChange={this.handleChange}
                  placeholder="Your name"
                />
              </div>
              <div className="search-input">
                <input
                  id="email"
                  className="submit-input input-bg-white"
                  name="email"
                  value={email.value}
                  onChange={this.handleChange}
                  placeholder="your.email@mail.com"
                />
              </div>
              <div className="search-input double">
                <div className="input-wrapper">
                  <input
                    id="contactNumber"
                    className="contactNumber input-bg-white"
                    name="contactNumber"
                    type="tel"
                    value={contactNumber.value}
                    onChange={this.handleChange}
                    placeholder="+1-123-245-6789"
                  />
                  {isShowError && contactNumber.hasError === true && (
                    <p className="error-text">{contactNumber.errorText}</p>
                  )}
                </div>
                <div className="input-wrapper">
                  <select
                    name="bestTimeToCall"
                    onChange={this.handleChangeSelect}
                    value={bestTimeToCall.value}
                  >
                    <option value="8am-1pm">8am-1pm</option>
                    <option value="1pm-6pm">1pm-6pm</option>
                    <option value="Anytime">Anytime</option>
                  </select>
                </div>
              </div>
              <div className="search-input">
                <input
                  id="text"
                  className="submit-input"
                  name="note"
                  value={note.value}
                  onChange={this.handleChange}
                  placeholder="Add a note (optional)"
                />
              </div>
            </SearchForm>
          )}
          {displaySuccessMessage && (
            <div className="thank-you" data-thank-you="">
              <h4 className="h3">
                Thank you for getting in touch! We'll respond soon.
              </h4>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default GroupPageSearchForm;
